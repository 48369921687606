var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.name),expression:"typeform.name"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
          },attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.typeform.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "name", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Apellido")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.lastName),expression:"typeform.lastName"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.lastName.$error,
          },attrs:{"type":"text","name":"lastName"},domProps:{"value":(_vm.typeform.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "lastName", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.lastName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.lastName.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Nombre de usuario")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.userName),expression:"typeform.userName"}],staticClass:"form-control",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.userName.$error,
          },attrs:{"type":"text","name":"userName"},domProps:{"value":(_vm.typeform.userName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "userName", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.userName.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.userName.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(" Cliente")]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.client),expression:"typeform.client"}],staticClass:"form-select",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.client.$error,
            },attrs:{"name":"client","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "client", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.clients),function(item,index){return _c('option',{key:index,domProps:{"value":item._id,"selected":_vm.typeform.client == item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.client.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.client.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])])],1),(!_vm.typeform._id)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Correo Electronico")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.email),expression:"typeform.email"}],staticClass:"form-control",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.email.$error,
            },attrs:{"type":"email","name":"email"},domProps:{"value":(_vm.typeform.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "email", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.email.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e(),(!_vm.$v.typeform.email.email)?_c('span',[_vm._v(" Ingrese un correo valido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Contraseña")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.password),expression:"typeform.password"}],staticClass:"form-control",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.password.$error,
            },attrs:{"type":"password","name":"password"},domProps:{"value":(_vm.typeform.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.typeform, "password", $event.target.value)}}}),(_vm.typesubmit && _vm.$v.typeform.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.password.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e(),(!_vm.$v.typeform.password.minLength)?_c('span',[_vm._v(" La contraseña tiene que tener al menos 6 caracteres. ")]):_vm._e()]):_vm._e()])])])],1):_vm._e(),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("Guardar")]),_c('button',{staticClass:"btn btn-secondary ms-1",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" Cancelar ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }